import { React, useState } from 'react'

import { AppBar, Box, Toolbar, IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import mobile from 'Assets/images/mobile-white.svg'
import profileIcon from 'Assets/images/profile.svg'
import signOutIcon from 'Assets/images/signOut.svg'

import { useJwt } from 'react-jwt'
import { useNavigate } from 'react-router-dom'

const NavBar = () => {
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState(null)
    const { decodedToken } = useJwt(localStorage.getItem('token') || '')

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSignOut = () => {
        setAnchorEl(null)
        setTimeout(()=>{
            localStorage.removeItem("token");
            navigate("/");
        },2000)
        
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ backgroundColor: "#1A485E" }}>
                <Toolbar style={{ zIndex: '1' }}>
                    <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                        <Box style={styles.heading_group}>
                            <h6 style={styles.heading}>Medicare UCC</h6>
                            <p style={styles.heading_capture}>Module</p>
                        </Box>
                    </Box>
                    <Box style={styles.nav_right_group} sx={{ p: 1 }}>
                        <img style={styles.mobile_img} src={mobile} alt='mobile' />
                        <a href='tel:1800762993' style={styles.support_number}>
                            Support 1800 762 993
                        </a>
                    </Box>
                    <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleClick}
                        name="CE1ID_ThreeDotsIcon"
                    >
                        <MoreVertIcon sx={{ fontSize: "30px" }} style={{ zIndex: -1 }} />
                    </IconButton>

                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                            <div style={styles.menuItem_group}>
                                <a name='CE1ID_ProfileIcon'>
                                    <img
                                        name='CE1ID_ProfileIcon'
                                        style={styles.icon}
                                        src={profileIcon}
                                        alt='profileIcon'
                                    />
                                </a>
                                <div name='CE1ID_ProfileIcon'>
                                    <a
                                        name='CE1ID_ProfileIcon'
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            color: "#00000099",
                                        }}
                                    >
                                        {localStorage.getItem("organisation_name")}
                                    </a>
                                    <div>
                                    <a 
                                    name='CE1ID_ProfileIcon'
                                    style={{ fontSize: "12px", color: "#A6A6A6B5" }}>
                                        {decodedToken ? decodedToken.username : ""}
                                    </a>
                                    </div>
                                </div>
                            </div>
                        </MenuItem>
                        <hr />
                        <MenuItem onClick={handleSignOut}>
                            <div style={styles.menuItem_group}>
                                <a name="CE1ID_SignOut">
                                    <img
                                        name="CE1ID_SignOut"
                                        style={styles.icon}
                                        src={signOutIcon}
                                        alt="SignOutIcon"
                                    />
                                </a>
                                <div>
                                    <a
                                        name="CE1ID_SignOut"
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            color: "#1A485E",
                                        }}
                                    >
                                        Sign Out
                                    </a>
                                </div>
                            </div>
                        </MenuItem>
                        <hr />
                        <MenuItem
                            onClick={handleClose}
                            sx={{
                                color: '#A6A6A6B5',
                                fontSize: '12px',
                                fontWeight: '400',
                                justifyContent: 'flex-end',
                                padding: '0px 19px 5px 0px',
                                userSelect: 'none',
                                '&:hover': {
                                    background: 'transparent',
                                    textDecoration: 'none',
                                    cursor: 'auto',
                                },
                            }}
                        >
                            v1.0.42
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

const styles = {
    menuItem_group: {
        display: 'flex',
        gap: '10px',
    },
    icon: { paddingRight: '10px', width: '20px', height: '20px' },
    nav_right_group: {
        display: 'flex',
        marginRight: '32px',
        gap: '10px',
    },
    support_number: {
        color: '#ffffff',
        textDecoration: 'none',
        fontSize: ' 19.4px',
        fontWeight: '500',
    },
    heading_group: {
        maxHeight: '55px',
        maxWidth: '100%',
        margin: '29px 0px 38px 74px',
        padding: '0px',
    },
    heading: {
        display: 'flex',
        width: '203.74px',
        flexDirection: 'column',
        margin: 0,
        flexShrink: 0,
        fontSize: '30px',
        fontWeight: '700',
    },
    heading_capture: {
        margin: 0,
    },
}

export default NavBar
